import Navigo from 'navigo';

import FaqToggle from './Components/FAQ/FaqToggle';
import { PersonPicker } from './Components/Pricing/PersonPicker';
import Pricing from './Components/Pricing/Pricing';
import MobileTabsDropdown from './Components/Tabs/MobileTabsDropdown';
import Faq from './Components/FAQ/Faq';
import LoginButtons from './Components/Navigation/LoginButtons';
import DemoGate from './Components/Demo/DemoGate';
import LogoMarquee from './Components/Animation/LogoMarquee';
import TabsMobileSlider from './Components/Tabs/TabsMobileSlider';
import VideoModal from './Components/Video/VideoModal';
import LandingPageToPricing from './Components/Buttons/LandingPageToPricing';
import Tracking from './Components/Tracking/Tracking';
import VideoJSPlayer from './Components/Video/VideoJSPlayer';

// All code is executed from here, the Navigo router
// handles on which page which code should be executd
// Add the page URL and the function to execute
// Static URL or with regular expression for CMS pages can be used

const router = new Navigo('/');

router.on(/courses\/(.*)/, () => {
  FaqToggle();
  MobileTabsDropdown();
  VideoModal();
  LandingPageToPricing();
  VideoJSPlayer();
});

router.on(/demos\/(.*)/, () => {
  DemoGate();
  FaqToggle();
});

router.on('/plans', () => {
  PersonPicker();
  Pricing();
  Faq();
  FaqToggle();
});

router.on('/en/plans', () => {
  PersonPicker();
  Pricing();
  Faq();
  FaqToggle();
});

router.on('/faq', () => {
  Faq();
});

router.on('/en/faq', () => {
  Faq();
});

router.on('/components/demo-gate', () => {
  DemoGate();
  FaqToggle();
});

router.on('/free-access/plans', () => {
  PersonPicker();
  Pricing();
  Faq();
  FaqToggle();
});

router.on('/en/free-access/plans', () => {
  PersonPicker();
  Pricing();
  Faq();
  FaqToggle();
});

LoginButtons();

try {
  LogoMarquee();
} catch (error) {
  console.warn(error);
}

try {
  TabsMobileSlider();
} catch (error) {
  console.warn(error);
}

try {
  Tracking();

  window.addEventListener(
    'CookiebotOnAccept',
    (e) => {
      Tracking();
    },
    false
  );

  window.addEventListener(
    'CookiebotOnDecline',
    (e) => {
      Tracking();
    },
    false
  );
} catch (error) {
  console.warn(error);
}

router.notFound(() => true);

router.resolve();
