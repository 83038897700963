import { createApp } from 'petite-vue';

export default () => {
  // immidiate conversion event for nongated_control variant
  // user doesn't see gate but demo right away in this case
  try {
    window.optibaseAddActiveVariantsListener((event) => {
      if (event.userFriendlyVariantId === 'nongated_control') {
        window.optibaseSendConversionEvent('demo_viewed');
      }
    });
  } catch (error) {
    console.warn(error, 'Optibase not loaded or installed');
  }

  // Petite Vue app to handle the demo gate form
  createApp({
    // Pass 'form' to the app to show the form initially in Webflow
    // Later in the app the screen will change based on the form submission
    // and show a success screen or error screen
    // use v-if or v-show to handle different screens in Webflow
    activeScreen: 'form',
    newsletter: false,
    // The form popup in Webflow is shown if this is false
    // use v-if on the popup in webflow to show or hide the form
    allowAccess: false,
    // Fields here are the fields in the form in Webflow
    // use v-model to bind the fields to the form in Webflow
    // mirrored here for use in app in here and send this structure to the webhook in Make
    // might be change in structure if the webhook expects a different structure
    // for example if Make.com is replace with another service
    form: {
      firstName: '',
      lastName: '',
      email: '',
      newsletterChecked: false,
      demoSlug: window.gatedDemoSlug,
      honey: false, // honeypot field
    },
    getURLParams() {
      // Get the URL parameters
      const urlParams = new URLSearchParams(window.location.search);
      // Parameter is set in Link in E-Mail
      const allowAccessDemo = urlParams.get('demo-universal');

      // '45552' is a random number to make sure users cant access
      // the demo without the by accident or trying to bypass the gate
      if (allowAccessDemo === '45552') {
        this.setLocalStorage(); // Call function to allow access for next time
        this.allowAccess = true; // Set allowAccess to true to show the demo immidiately to the user
      }
    },
    setLocalStorage() {
      // Set local storage to allow access for next time
      localStorage.setItem('demo-allow', true);
    },
    readLocalStorage() {
      // Check if user has already rights to access the demo
      const allow = localStorage.getItem('demo-allow');

      if (allow) {
        // Set allowAccess to true to show the demo immidiately to the user
        this.allowAccess = true;
        // Send conversion event for demo_viewed if user has access
        // Might give error here if Optibase is not loaded or installed
        try {
          window.optibaseAddActiveVariantsListener(() => {
            window.optibaseSendConversionEvent('demo_viewed');
          });
        } catch (error) {
          console.warn(error, 'Optibase not loaded or installed');
        }
      }
    },
    startup() {
      // Call in Webflow Petite Vue app to start the app and set the initial values
      // Can use Petite Vue lifecycle hooks to run functions on mount or v-effect on App root
      this.getURLParams();
      this.readLocalStorage();
    },
    sendForm() {
      // The id of the form in Webflow on the demo gate app
      const form = document.querySelector('#demo-gate-form');

      // Check if the form is valid to trigger the browser validation
      // and decide later to post the form or show form show user which fields are missing
      const formValidity = form.checkValidity();

      // Bind the newsletter checkbox to the form object
      this.form.newsletterChecked = this.newsletter;

      if (!formValidity) {
        // if form is not valid show the user which fields are missing
        // native javascript form validation
        form.reportValidity();
      } else {
        (async () => {
          const rawResponse = await fetch(
            // Make Webhook to receive the form data
            // Expects a JSON object so don't send raw form data
            'https://hook.eu1.make.com/j9qahdpytfbepdsxqv5vv779144w6iqh',
            {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(this.form), // Make.com will parse this JSON object string
            }
          );

          // Wait for the response from the webhook
          // to decide what to do next
          const response = await rawResponse;

          // Call the function to handle the response
          this.handleFormResponse(response.status);
        })();
      }
    },
    handleFormResponse(statusCode) {
      // Status code is the response from the webhook/Microservice
      // 200 is success for new created user
      // 201 is success for existing user
      // 400 is error in form or something went wrong in Make.com/Microservice

      // If status code is 200 the form was successfully submitted
      // Show success screen based on the newsletter checkbox
      if (statusCode === 200) {
        if (this.newsletter) {
          this.activeScreen = 'newsletter-register';
        }

        if (!this.newsletter) {
          this.activeScreen = 'normal-register';
        }
      }

      if (statusCode === 201) {
        // If status code is 201 the user already exists
        // Show existing user screen
        this.activeScreen = 'existing-user';

        setTimeout(() => {
          this.setLocalStorage();
          this.allowAccess = true;
        }, 5000);
      }

      if (statusCode === 400) {
        // If status code is 400 the form was not valid and something happened wrongly in Make.com
        this.activeScreen = 'error';
      }
    },
  }).mount('#demo-gate'); // Make sure the id matches the app div in Webflow
};
